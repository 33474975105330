<template>
  <div>
    <q-form ref="editForm">
      <!--의견 청취-->
      <c-card title="제안 의견" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <!--제목-->
            <c-text
              required
              :editable="editable"
              label="제목"
              name="boardTitle"
              v-model="data.boardTitle">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <!--위치-->
            <c-text
              :editable="editable"
              label="위치"
              name="boardLocation"
              v-model="data.boardLocation">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <!--의견게시일-->
            <c-datepicker
              required
              :editable="editable"
              label="의견게시일"
              name="boardDate"
              v-model="data.boardDate">
            </c-datepicker>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <c-plant
              required
              :editable="editable"
              type="edit"
              name="plantCd"
              v-model="data.plantCd">
            </c-plant>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <!--개요-->
            <c-textarea
              required
              :editable="editable"
              :rows="4"
              label="개요"
              name="boardContents"
              v-model="data.boardContents">
            </c-textarea>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <!--현황 및 문제점-->
            <c-textarea
              :editable="editable"
              :rows="4"
              label="현황 및 문제점"
              name="boardProblem"
              v-model="data.boardProblem">
            </c-textarea>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <!--개선방안-->
            <c-textarea
              :editable="editable"
              :rows="4"
              label="개선방안"
              name="boardImprove"
              v-model="data.boardImprove">
            </c-textarea>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <!--기대효과-->
            <c-textarea
              :editable="editable"
              :rows="4"
              label="기대효과"
              name="boardBenefit"
              v-model="data.boardBenefit">
            </c-textarea>
          </div>
        </template>
      </c-card>
      <c-dialog :param="popupOptions"></c-dialog>
      <div class="popup-bottom-bar">
        <div class="popup-bottom-bar-btngroup">
          <q-btn-group outline >
            <!--삭제-->
            <c-btn flat v-if="editable && popupParam.boardId && deletable && isOwner" label="삭제" icon="delete_forever" @btnClicked="removeData" />
            <!--저장-->
            <c-btn
              flat
              v-if="(editable && popupParam.boardId && isOwner) || (editable && !popupParam.boardId)"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="data"
              :mappingType="mappingType"
              label="저장"
              icon="save"
              @beforeAction="saveData"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </div>
        <div class="popup-bottom-bar-close">
          <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'saf-opinion-board-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        boardId: '',
      }),
    },
  },
  data() {
    return {
      attachInfoRequest: {
        isSubmit: '',
        taskClassCd: 'SOB_BOARD_REQUEST',
        taskKey: '',
      },
      saveUrl: transactionConfig.sai.sob.board.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isOwner: false,
      isdelete: false,
      deletable: false,
      data: {
        boardId: '',
        plantCd: '',
        boardDate: '',
        boardContents: '',
        boardTitle: '',
        boardTypeCd: null,
        boardProblem: '',
        boardResultContent: '',
        boardImprove: '',
        boardBenefit: '',
        boardLocation: '',
        policyCommentModelList: [],
        regUserId: '',
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.data.boardDate = this.$comm.getToday();
      this.data.regUserId = this.$store.getters.user.userId;
      // url setting
      this.detailUrl = selectConfig.sai.sob.board.get.url;
      this.insertUrl = transactionConfig.sai.sob.board.insert.url;
      this.updateUrl = transactionConfig.sai.sob.board.update.url;
      this.deleteUrl = transactionConfig.sai.sob.board.delete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.boardId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.boardId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          if (this.data.regUserId == this.$store.getters.user.userId) {
            this.isOwner = true;
            this.deletable = true;
          } else {
            this.isOwner = false;
            this.deletable = false;
          }
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          this.$set(this.attachInfoRequest, 'taskKey', this.popupParam.boardId)
        },);
      }
    },
    saveData() {
      if (this.popupParam.boardId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',    // 확인
            message: '저장하시겠습니까?', // 저장하시겠습니까?     // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              // this.data.regUserId = this.$store.getters.user.userId;
              // this.data.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',  // 확인
        message: '삭제하시겠습니까?', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.boardId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.boardId = result.data
      this.$set(this.attachInfoRequest, 'taskKey', this.popupParam.boardId)
      this.$set(this.attachInfoRequest, 'isSubmit', uid())
      this.getDetail();
    },
    saveComment(data) {
      if (!data.policyComments) {
          window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요',  // 필수 입력값을 입력해 주세요. [내용]
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      window.getApp.$emit('CONFIRM', {
        title: '확인',     // 확인
        message: '댓글저장하시겠습니까?',   // 댓글저장하시겠습니까?
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          if (data.editFlag && data.editFlag === 'C') {
            this.$http.url = transactionConfig.sai.shm.policy.insert.comment.url;
            this.$http.type = 'POST';
          } else {
            this.$http.url = transactionConfig.sai.shm.policy.update.comment.url;
            this.$http.type = 'PUT';
          }
          this.$http.param = data;
          this.$http.request((_result) => {
            data.policyCommentId = _result.data
            this.$refs['table'].selected = [];
            window.getApp.$emit('APP_REQUEST_SUCCESS');

            this.getDetail();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    removeComment(data) {
      window.getApp.$emit('CONFIRM', {
        title: '확인',  // 확인
        message: '삭제하시겠습니까?', // 삭제하시겠습니까?,
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(transactionConfig.sai.shm.policy.delete.comment.url,
            this.popupParam.boardId, data.policyCommentId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.$refs['table'].selected = [];
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.data.policyCommentModelList = this.$_.reject(this.data.policyCommentModelList, {
              policyCommentId: data.policyCommentId,
            });
            this.getDetail();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    add() {
      this.data.policyCommentModelList.splice(0, 0, {
        boardId: this.popupParam.boardId,
        policyCommentId: uid(),
        policyComments: '',
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        regName: this.$store.getters.user.userName + ' / ' + this.$comm.getTodayDateTime(),
        editFlag: "C",
      });
    },
    closePopUps() {
      this.$emit('closePopup')
    },
  }
};
</script>
